<template>
	<a-layout>
		<a-layout-sider breakpoint="lg" collapsed-width="0" v-model="collapsed" @breakpoint="onBreakpoint">
			<a-menu theme="dark" style="height: 100%" mode="inline" :selectedKeys="[$route.name]" @click="onMenuChange">
				<template v-for="data in menus">
					<a-sub-menu :key="data.httpPath" v-if="Array.isArray(data.children)">
						<span slot="title">
							<a-icon :type="data.icon" />
							<span>{{ data.httpPathName }}</span>
						</span>
						<a-menu-item :key="item.id" v-for="item in data.children">{{ item.httpPathName }}</a-menu-item>
					</a-sub-menu>
					<a-menu-item :key="data.httpPath" v-else>
						<a-icon :type="data.icon" />
						{{ data.httpPathName }}
					</a-menu-item>
				</template>
			</a-menu>
		</a-layout-sider>
		<a-layout-content :style="{ minHeight: '100vh' }">
			<div style="padding: 0 16px;"><router-view /></div>
			<!-- <a-back-top /> -->
		</a-layout-content>
	</a-layout>
</template>

<script>
	import WsHeader from '@/components/WsHeader';
	// import { menus, smenus } from '@/config/menus';
	import { mapState } from 'vuex';
	export default {
		components: {
			WsHeader
		},
		data() {
			return {
				collapsed: false,
				breakpoint: false,
				isAdmin: false,
				// menus,
				selectedKeys: []
			};
		},
		computed: mapState(['token', 'minfo', 'menus']),
		methods: {
			onMenuChange(menu) {
				// const menu = this.menus.find(v => v.id == e.key)
				if (menu.key == this.$route.name) {
					return;
				}
				console.info(menu)
				this.$router.push({
					name: menu.key
				});
			},
			makeMenuStatus() {
				// const { name } = this.$route;
				// this.selectedKeys = [name];
			},
			onBreakpoint(e) {
				this.breakpoint = e
			}
		},
		mounted() {
			if (this.$store.state.token) {
				const minfo = this.$storage.get('minfo') || {};
				// this.isAdmin = minfo.isAdmin;
				// this.menus = this.isAdmin ? menus : smenus;
				// this.$nextTick(this.makeMenuStatus);
			} else {
				this.$router.replace('/login');
			}
		},
		watch: {
			$route(route) {
				this.collapsed = this.breakpoint
				console.info('$route', this.collapsed)
				this.makeMenuStatus();
			}
		}
	};
</script>